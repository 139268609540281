import { Link } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import GameContainer from '../../components/GameContainer';
import PageLayout from '../../components/PageLayout';

import * as styles from './index.module.scss';

const PrivacyPolicyPage = () => {
    return (
        <PageLayout>
            <Link to='/'>
                <img src='/skissan.svg' alt='Skissan' />
            </Link>
            <Helmet>
                <title>Privacy Policy - Skissan</title>
            </Helmet>
            <h1>Privacy Policy</h1>
            <h2>Your privacy is important to us</h2>
            <GameContainer
                containerSize='large'
                textAlign='left'
                className={styles.privacyPolicy}
            >
                <h2>What this policy covers</h2>
                <p>
                    Your privacy is important to us, and so is being transparent
                    about how we collect, use, and share information about you.
                    This policy is intended to help you understand:
                </p>
                <ul>
                    <li>
                        <a href='#1'>What information we collect about you</a>
                    </li>
                    <li>
                        <a href='#2'>How we use information we collect</a>
                    </li>
                    <li>
                        <a href='#3'>How we share information we collect</a>
                    </li>
                    <li>
                        <a href='#4'>
                            How we store and secure information we collect
                        </a>
                    </li>
                    <li>
                        <a href='#5'>
                            How to access and control your information
                        </a>
                    </li>
                    <li>
                        <a href='#6'>
                            How we transfer information we collect
                            internationally
                        </a>
                    </li>
                    <li>
                        <a href='#7'>Other important privacy information</a>
                    </li>
                </ul>
                <p>
                    This Privacy Policy covers the information we collect about
                    you when you use our products or services, or otherwise
                    interact with us (for example, support through e-mail or
                    Facebook Messenger), unless a different privacy policy is
                    displayed. This policy also explains your choices about how
                    we use information about you. Your choices include how you
                    can object to certain uses of information about you and how
                    you can access and update certain information about you.{' '}
                    <strong>
                        If you do not agree with this policy, do not access or
                        use our Services or interact with any other aspect of
                        our business.
                    </strong>
                </p>
                <p>
                    When we refer to "Slidesome," "we," or "us" in this policy,
                    we mean Slidesome ehf., which controls the information
                    Slidesome collects when you use the Services. We offer a
                    website, and background services that deliver data for
                    active games. We refer to all of these services and website
                    as "Services" in this policy.
                </p>

                <h2 name='1' id='1'>
                    What information we collect about you
                </h2>
                <p>
                    We collect information about you when you provide it to us,
                    when you use our Services, and when other sources provide it
                    to us, as further described below.
                </p>

                <h3>Information you provide to us</h3>
                <p>
                    We collect information about you when you input it into the
                    Services or otherwise provide it directly to us.
                </p>
                <ul>
                    <li>
                        <strong>Account and Profile Information</strong>: We
                        collect information about you when you register for an
                        account, create or modify your profile, set preferences,
                        sign-up for or make purchases through the Services. For
                        example, you provide your contact information and, in
                        some cases, billing information when you register for
                        the Services. We keep track of your preferences when you
                        select settings within the Services.
                    </li>
                    <li>
                        <strong>Payment Information</strong>: We collect certain
                        payment information when you purchase for certain paid
                        Services. For example, the PayPal name, e-mail address,
                        amount, etc. We never receive, or store credit-card
                        numbers in our Services.
                    </li>
                </ul>

                <h3>
                    Information we collect automatically when you use the
                    Services
                </h3>
                <p>
                    We collect information about you when you use our Services,
                    including browsing our websites and taking certain actions
                    within the Services.
                </p>
                <ul>
                    <li>
                        <strong>Your use of the Services</strong>: We keep track
                        of certain information about you when you visit and
                        interact with any of our Services. This information
                        includes the features you use; display name, drawings,
                        chat, and other links you click on; the type, size and
                        filenames of attachments you upload to the Services.
                    </li>
                    <li>
                        <strong>Device and Connection Information</strong>: We
                        collect information about your computer, phone, tablet,
                        or other devices you use to access the Services. We
                        collect information about your operating system, browser
                        type, IP address, URLs of referring/exit pages, device
                        identifiers, and crash data. We use your IP address in
                        order to approximate your location to provide you with a
                        better Service experience, such as displaying the
                        correct currency for your region. How much of this
                        information we collect depends on the type and settings
                        of the device you use to access the Services.
                    </li>
                    <li>
                        <strong>Cookies and Other Tracking Technologies</strong>
                        : Slidesome and our third-party partners, such as our
                        analytics partners, use cookies and other tracking
                        technologies (e.g., web beacons, device identifiers and
                        pixels) to provide functionality and to recognize you
                        across different Services and devices.
                    </li>
                </ul>

                <h3>Information we receive from other sources</h3>
                <p>
                    We receive information about you from third party services.
                </p>
                <ul>
                    <li>
                        <strong>Other services you link to your account</strong>
                        : We receive information about you when you integrate or
                        link a third-party service with our Services. For
                        example, if you create an account or log into the
                        Services using your Google credentials, we receive your
                        name and email address as permitted by your Google
                        profile settings in order to authenticate you. You or
                        your administrator may also integrate our Services with
                        other services you use, such as to allow you to access,
                        store, share and edit certain content from a third-party
                        through our Services. For example, you may authorize our
                        Services to access and display files from a third-party
                        document-sharing service within the Services interface.
                        The information we receive when you link or integrate
                        our Services with a third-party service depends on the
                        settings, permissions and privacy policy controlled by
                        that third-party service. You should always check the
                        privacy settings and notices in these third-party
                        services to understand what data may be disclosed to us
                        or shared with our Services.
                    </li>
                </ul>

                <h2 name='2' id='2'>
                    How we use information we collect
                </h2>
                <p>
                    How we use the information we collect depends in part on
                    which Services you use, how you use them, and any
                    preferences you have communicated to us. Below are the
                    specific purposes for which we use the information we
                    collect about you.
                </p>
                <ul>
                    <li>
                        <strong>
                            To provide the Services and personalize your
                            experience
                        </strong>
                        : We use information about you to provide the Services
                        to you, including to process transactions with you,
                        authenticate you when you log in, provide customer
                        support, and operate and maintain the Services. For
                        example, we use the access token from third-party
                        platforms such as Facebook and Google to authenticate
                        you.
                    </li>
                    <li>
                        <strong>For research and development</strong>: We are
                        always looking for ways to make our Services smarter,
                        faster, secure, integrated and useful to you. We use
                        collective learnings about how people use our Services
                        and feedback provided directly to us to troubleshoot and
                        to identify trends, usage, activity patterns and areas
                        for integration and improvement of the Services. For
                        example, we used information collected about how users
                        create custom rooms from within the product to design a
                        better, more user-friendly custom room creation flow. We
                        also test and analyze certain new features with some
                        users before rolling the feature out to all users.
                    </li>
                    <li>
                        <strong>
                            To communicate with you about the Services
                        </strong>
                        : We use your contact information to send transactional
                        communications via email and within the Services,
                        including confirming your purchases, reminding you of
                        subscription expirations, responding to your comments,
                        questions and requests, providing customer support, and
                        sending you technical notices, updates, security alerts,
                        and administrative messages. We also provide tailored
                        communications based on your activity and interactions
                        with us. For example, certain actions you take in the
                        Services may automatically trigger a feature or
                        third-party app suggestion within the Services that
                        would make that task easier. We also send you
                        communications as you onboard to a particular Service to
                        help you become more proficient in using that Service.
                        These communications are part of the Services and in
                        most cases you cannot opt out of them. If an opt out is
                        available, you will find that option within the
                        communication itself or in your account settings.
                    </li>
                    <li>
                        <strong>Customer support</strong>: We use your
                        information to resolve technical issues you encounter,
                        to respond to your requests for assistance, to analyze
                        crash information, and to repair and improve the
                        Services.
                    </li>
                    <li>
                        <strong>For safety and security</strong>: We use
                        information about you and your Service use to verify
                        accounts and activity, to monitor suspicious or
                        fraudulent activity and to identify violations of
                        Service policies.
                    </li>
                    <li>
                        <strong>With your consent</strong>: We use information
                        about you where you have given us consent to do so for a
                        specific purpose not listed above. For example, we may
                        publish testimonials or featured customer stories to
                        promote the Services, with your permission.
                    </li>
                </ul>

                <h2 name='3' id='3'>
                    How we share information we collect
                </h2>
                <p>
                    We are not in the business of selling information about you
                    to advertisers or other third parties. We share information
                    we collect about you in the ways discussed below, such as
                    allowing our users to share their custom rooms and drawings.
                </p>
                <h3>Sharing with other Service users</h3>
                <p>
                    When you use the Services, we share certain information
                    about you with other Service users.
                </p>
                <ul>
                    <li>
                        <strong>For collaboration</strong>: You can create
                        content, which may contain information about you, and
                        grant permission to others to see, share, edit, copy and
                        download that content based on settings you or your
                        administrator (if applicable) select. Some of the
                        collaboration features of the Services display some or
                        all of your drawings to other Service users when you
                        play with other users.
                    </li>
                </ul>

                <h3>Sharing with third parties</h3>
                <p>
                    We share information with third parties that help us
                    operate, provide, improve, integrate, customize and support
                    our Services.
                </p>
                <ul>
                    <li>
                        <strong>Service Providers</strong>: We work with
                        third-party service providers to provide hosting,
                        maintenance, backup, storage, virtual infrastructure,
                        payment processing, analysis and other services for us,
                        which may require them to access or use information
                        about you. If a service provider needs to access
                        information about you to perform services on our behalf,
                        they do so under instruction from us, including abiding
                        by policies and procedures designed to protect your
                        information.
                    </li>
                    <li>
                        <strong>Links to Third-Party Sites</strong>: The
                        Services may include links that direct you to other
                        websites or services whose privacy practices may differ
                        from ours. Your use of and any information you submit to
                        any of those third-party sites is governed by their
                        privacy policies, not this one.
                    </li>
                    <li>
                        <strong>Third-Party Widgets</strong>: Some of our
                        Services contain widgets and social media features, such
                        as the Twitter "tweet" button. These widgets and
                        features collect your IP address, which page you are
                        visiting on the Services, and may set a cookie to enable
                        the feature to function properly. Widgets and social
                        media features are either hosted by a third party or
                        hosted directly on our Services. Your interactions with
                        these features are governed by the privacy policy of the
                        company providing it.
                    </li>
                    <li>
                        <strong>With your consent</strong>: We share information
                        about you with third parties when you give us consent to
                        do so. For example, we may display personal testimonials
                        of satisfied customers on our public website. With your
                        consent, we may post your name alongside the
                        testimonial.
                    </li>
                    <li>
                        <strong>
                            Compliance with Enforcement Requests and Applicable
                            Laws; Enforcement of Our Rights
                        </strong>
                        : In exceptional circumstances, we may share information
                        about you with a third party if we believe that sharing
                        is reasonably necessary to (a) comply with any
                        applicable law, regulation, legal process or
                        governmental request, including to meet national
                        security requirements, (b) enforce our agreements,
                        policies and terms of service, (c) protect the security
                        or integrity of our products and services, (d) protect
                        Slidesome, our customers or the public from harm or
                        illegal activities, or (e) respond to an emergency which
                        we believe in good faith requires us to disclose
                        information to assist in preventing the death or serious
                        bodily injury of any person.
                    </li>
                    <li>
                        <strong>Advertising partners</strong>: Subject to your
                        settings, we provide certain Information to advertisers
                        who will use them to serve you with ads in our Services,
                        and we measure who sees and clicks on their ads. We also
                        share advertising identifiers, along with the interests
                        or other characteristics of a device or the person using
                        it, to help partners decide whether to serve an ad to
                        that device or to enable them to conduct marketing,
                        brand analysis, personalize advertising, or similar
                        activities.
                        <p>
                            <strong>List of advertising partners:</strong>
                        </p>
                        <ul>
                            <li>
                                Google -{' '}
                                <a href='https://policies.google.com/technologies/partner-sites'>
                                    https://policies.google.com/technologies/partner-sites
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>

                <h2 name='4' id='4'>
                    How we store and secure information we collect
                </h2>
                <h3>Information storage and security</h3>
                <p>
                    We use cloud providers in Ireland to host the information we
                    collect, and we use technical measures to secure your data.
                    While we implement safeguards designed to protect your
                    information, no security system is impenetrable and due to
                    the inherent nature of the Internet, we cannot guarantee
                    that data, during transmission through the Internet or while
                    stored on our systems or otherwise in our care, is
                    absolutely safe from intrusion by others. We will respond to
                    requests about this within a reasonable timeframe.
                </p>
                <h3>How long we keep information</h3>
                <p>
                    How long we keep information we collect about you depends on
                    the type of information, as described in further detail
                    below. After such time, we will either delete or anonymize
                    your information or, if this is not possible (for example,
                    because the information has been stored in backup archives),
                    then we will securely store your information and isolate it
                    from any further use until deletion is possible.
                </p>
                <ul>
                    <li>
                        Account information: We retain your account information
                        until you delete your account. We also retain some of
                        your information as necessary to comply with our legal
                        obligations, to resolve disputes, to enforce our
                        agreements, to support business operations and to
                        continue to develop and improve our Services. Where we
                        retain information for Service improvement and
                        development, we take steps to eliminate information that
                        directly identifies you, and we only use the information
                        to uncover collective insights about the use of our
                        Services, not to specifically analyze personal
                        characteristics about you.
                    </li>
                </ul>

                <h2 name='5' id='5'>
                    How to access and control your information
                </h2>
                <p>
                    You have certain choices available to you when it comes to
                    your information. Below is a summary of those choices, how
                    to exercise them and any limitations. We will respond to
                    requests about this within a reasonable timeframe.
                </p>
                <h3>Your Choices:</h3>
                <p>
                    You have the right to request a copy of your information, to
                    object to our use of your information (including for
                    marketing purposes), to request the deletion or restriction
                    of your information, or to request your information in a
                    structured, electronic format. Below, we describe the tools
                    and processes for making these requests. You can exercise
                    some of the choices by logging into the Services and using
                    settings available within the Services or your account. For
                    all other requests, you may contact us as provided in the
                    Contact Us section below to request assistance.
                </p>
                <p>
                    Your request and choices may be limited in certain cases for
                    example, if fulfilling your request would reveal information
                    about another person, or if you ask to delete information
                    which we are permitted by law or have compelling legitimate
                    interests to keep. If you have unresolved concerns, you may
                    have the right to complain to a data protection authority in
                    the country where you live, where you work or where you feel
                    your rights were infringed.
                </p>
                <ul>
                    <li>
                        <strong>Access and update your information</strong>: Our
                        Services give you the ability to access and update
                        certain information about you from within the Service.
                        For example, you can access your profile information
                        from your account. You can update your profile
                        information within your profile settings and modify
                        content that contains information about you using the
                        editing tools associated with that content, such as your
                        profile picture.
                    </li>
                    <li>Remove custom rooms</li>
                    <li>
                        <strong>Delete your information</strong>: Our Services
                        give you the ability to delete certain information about
                        you from within the Service. For example, you can remove
                        content that contains information about you using the
                        editing tools associated with that content and you can
                        remove certain profile information within your profile
                        settings. Please note, however, that we may need to
                        retain certain information for record keeping purposes,
                        to complete transactions or to comply with our legal
                        obligations.
                    </li>
                    <li>
                        <strong>
                            Request that we stop using your information
                        </strong>
                        : In some cases, you may ask us to stop accessing,
                        storing, using and otherwise processing your information
                        where you believe we don't have the appropriate rights
                        to do so. For example, if you believe a Services account
                        was created for you without your permission or you are
                        no longer an active user, you can request that we delete
                        your account as provided in this policy. Where you gave
                        us consent to use your information for a limited
                        purpose, you can contact us to withdraw that consent,
                        but this will not affect any processing that has already
                        taken place at the time. You can also opt-out of our use
                        of your information for marketing purposes by contacting
                        us, as provided below. When you make such requests, we
                        may need time to investigate and facilitate your
                        request. If there is a delay or dispute as to whether we
                        have the right to continue using your information, we
                        will restrict any further use of your information until
                        the request is honored or the dispute is resolved,
                        provided your administrator does not object (where
                        applicable).
                    </li>
                    <li>
                        <strong>Opt out of communications</strong>: You may opt
                        out of receiving promotional communications from us by
                        using the unsubscribe link within each email, updating
                        your email preferences within your Service account
                        settings menu, or by contacting us as provided below to
                        have your contact information removed from our
                        promotional email list or registration database. Even
                        after you opt out from receiving promotional messages
                        from us, you will continue to receive transactional
                        messages from us regarding our Services. You can opt out
                        of some notification messages in your account settings.
                    </li>
                </ul>

                <h2 name='6' id='6'>
                    How we transfer information we collect internationally
                </h2>
                <h3>International transfers of information we collect</h3>
                <p>
                    We collect information globally and primarily store that
                    information in Ireland. We transfer, process and store your
                    information outside of your country of residence, to
                    wherever we, Slidesome or our third-party service providers
                    operate for the purpose of providing you the Services.
                    Whenever we transfer your information, we take steps to
                    protect it.
                </p>

                <h2 name='7' id='7'>
                    Other important privacy information
                </h2>
                <h3>Notice to End Users</h3>
                <p>
                    Our products are intended for both personal use and use by
                    organizations. Where the Services are made available to you
                    through an organization (e.g. your employer), that
                    organization is the administrator of the Services and is
                    responsible for the end-users and/or Service sites over
                    which it has control. If this is the case, please direct
                    your data privacy questions to your administrator, as your
                    use of the Services is subject to that organization's
                    policies. We are not responsible for the privacy or security
                    practices of an administrator's organization, which may be
                    different than this policy.
                </p>
                <h3>Our policy towards children</h3>
                <p>
                    The Services are not directed to individuals under 16. We do
                    not knowingly collect personal information from children
                    under 16. If we become aware that a child under 16 has
                    provided us with personal information, we will take steps to
                    delete such information. If you become aware that a child
                    has provided us with personal information, please contact us
                    as provided in the Contact Us section below.
                </p>
                <h3>Changes to our Privacy Policy</h3>
                <p>
                    We may change this privacy policy from time to time. We will
                    post any privacy policy changes on this page and, if the
                    changes are significant, we will provide a more prominent
                    notice by adding a notice on the Services homepages, login
                    screens, or by sending you an email notification. We
                    encourage you to review our privacy policy whenever you use
                    the Services to stay informed about our information
                    practices and the ways you can help protect your privacy.
                </p>
                <p>
                    If you disagree with any changes to this privacy policy, you
                    will need to stop using the Services and deactivate your
                    account(s), as outlined above.
                </p>

                <h2 name='contact' id='contact'>
                    Contact Us
                </h2>
                <p>
                    Your information is controlled by Slidesome ehf. If you have
                    questions or concerns about how your information is handled,
                    please direct your inquiry to Slidesome ehf., as set forth
                    below.
                </p>
                <p>
                    Slidesome ehf.
                    <br />
                    Reykjavik, Iceland.
                    <br />
                    E-mail:{' '}
                    <a href='mailto:support@slidesome.com'>
                        support@slidesome.com
                    </a>
                </p>

                <h2>Update</h2>
                <p>
                    This Privacy Policy was last updated on: Monday, October
                    25th, 2021.
                </p>
                <p>
                    Should we update, amend or make any changes to our privacy
                    policy, those changes will be posted here.
                </p>
            </GameContainer>
            <div style={{ marginTop: '2rem' }}>
                <Link to='/games'>← Back to games list</Link>
            </div>
        </PageLayout>
    );
};

export default PrivacyPolicyPage;
